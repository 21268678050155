<template>
  <div class="captcha-box">
    <div class="font-regular-12 gray7--text mb-2">
      {{ $t("auth.loginPage.loginForm.enterAnswer") }}
    </div>

    <div class="d-flex align-center justify-start">
      <div v-if="loading">
        <v-progress-circular
          :size="25"
          :width="2"
          color="primary"
          indeterminate
          style="width: 112px"
        ></v-progress-circular>
      </div>

      <img v-else :src="captchaImage" width="112" height="30" />

      <v-text-field
        v-model="captchaAnswer"
        :rules="captchaRules"
        required
        maxlength="5"
        filled
        hide-details
        class="theme-custom-input captcha-custom-input mx-3"
        height="30"
        @input="setCaptchaInfo"
      >
      </v-text-field>

      <v-btn
        icon
        color="transparent"
        height="16"
        width="16"
        :ripple="false"
        :disabled="loading"
        @click="getCaptcha"
      >
        <v-icon size="16" color="gray9" strokeWidth="1.5">$reload</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { AuthService } from "@/services";

export default {
  name: "captcha",
  props: {
    reload: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      captchaImage: "",
      captchaId: "",
      captchaAnswer: "",
      captchaRules: [
        (v) => !!v || this.$t("auth.loginPage.loginForm.captchaNotEmpty"),
      ],
    };
  },
  watch: {
    reload(val) {
      if (val) this.getCaptcha();
    },
  },
  mounted() {
    this.getCaptcha();
  },
  methods: {
    getCaptcha() {
      this.loading = true;
      this.captchaAnswer = "";
      this.captchaId = "";
      AuthService.getCaptcha().then((res) => {
        this.generateCaptchaData(res.data);
      });
    },
    // reloadCaptcha() {
    //   this.loading = true;
    //   AuthService.reloadCaptcha(this.captchaId).then((res) => {
    //     this.generateCaptchaData(res.data);
    //   });
    // },
    generateCaptchaData(data) {
      this.captchaId = data.key;
      this.captchaImage = "data:image/png;base64," + data.image;
      this.loading = false;
    },
    setCaptchaInfo() {
      let data = {
        captchaAnswer: this.captchaAnswer,
        captchaKey: this.captchaId,
      };
      this.$emit("setCaptchaInfo", data);
    },
  },
};
</script>

<style scoped lang="scss">
@import "Captcha";
</style>
